<template #default>
  <div class="container-fluid p-0 m-0">
    <div class="row m-0 pt-5 bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-8 m-0 p-0 text-Rot-Schatten">
        <h1 v-if="sprachID === 0">Mitteilungsblatt</h1>
        <h1 v-if="sprachID === 1">Newsletter</h1>
      </div>
    </div>

    <div class="row m-0 p-0 bg-WeissTransparent justifyCenter">
      <div class="col-11 col-md-8 m-0 pt-5">
        <p v-if="sprachID === 0">
          Das Mitteilungsblatt wird seit 1977 herausgegeben. Der Inhalt ist eine Mischung
          aus Berichten zur Taxonomie und Nomenklatur von Pflanzen (vor allem der Gattung
          Mammillaria, aber auch der Gattungen Coryphantha, Escobaria und Neolloydia ) ,
          wissenschaftlichen Abhandlungen, Erstbeschreibungen, Reiseberichten,
          Feldnotizen, Kulturhinweisen, Kurzberichten und vielen anderen Themengebieten.
        </p>
        <p v-if="sprachID === 0">
          Seit 1994 erscheint das Arbeitsmaterial für Mitglieder des AfM alle 3 Monate mit
          64 Innenseiten und Farbdrucken.
        </p>
        <p v-if="sprachID === 1">
          The newsletter has been published since 1977. The content is one Mixture of
          reports on taxonomy and nomenclature of plants (pre especially of the genus
          Mammillaria, but also of the genera Coryphantha, Escobaria and Neolloydia ),
          scientific treatises, First descriptions, travel reports, field notes, cultural
          references, Short reports and many other topics.
        </p>
        <p v-if="sprachID === 1">
          Since 1994, the working material for members of the AfM has been published every
          3rd Months with 64 inside pages and color prints.
        </p>
      </div>
    </div>

    <div class="row m-0 p-0 bg-WeissTransparent justifyCenter">
      <div class="col-12 col-md-8 m-0 p-0">
        <Jahr2025/>
        <Jahr2024 />
        <Jahr2023 />
        <Jahr2022 />
        <Jahr2021 />
        <Jahr2020 />
        <Jahr2019 />
        <Jahr2018 />
        <Jahr2017 />
        <Jahr2016 />
        <Jahr2015 />
        <Jahr2014 />
        <Jahr2013 />
        <Jahr2012 />
        <Jahr2011 />
        <Jahr2010 />
        <Jahr2009 />
        <Jahr2008 />
        <Jahr2007 />
        <Jahr2006 />
        <Jahr2005 />
        <Jahr2004 />
        <Jahr2003 />
        <Jahr2002 />
        <Jahr2001 />
        <Jahr2000 />
        <div class="div stickyShop">
          <ul class="noDeko">
            <li class="borderRund p-3" @click="openBestellung()">
              <h2 v-if="sprachID === 0" class="bold">Bestellformular</h2>
              <h2 v-if="sprachID === 0" class="bold">Hier</h2>
              <h2 v-if="sprachID === 1" class="bold">Order form</h2>
              <h2 v-if="sprachID === 1" class="bold">Here</h2>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Jahr2025 from "./Jahrgaenge/J2025.vue";
import Jahr2024 from "./Jahrgaenge/J2024.vue";
import Jahr2023 from "./Jahrgaenge/J2023.vue";
import Jahr2022 from "./Jahrgaenge/J2022.vue";
import Jahr2021 from "./Jahrgaenge/J2021.vue";
import Jahr2020 from "./Jahrgaenge/J2020.vue";
import Jahr2019 from "./Jahrgaenge/J2019.vue";
import Jahr2018 from "./Jahrgaenge/J2018.vue";
import Jahr2017 from "./Jahrgaenge/J2017.vue";
import Jahr2016 from "./Jahrgaenge/J2016.vue";
import Jahr2015 from "./Jahrgaenge/J2015.vue";
import Jahr2014 from "./Jahrgaenge/J2014.vue";
import Jahr2013 from "./Jahrgaenge/J2013.vue";
import Jahr2012 from "./Jahrgaenge/J2012.vue";
import Jahr2011 from "./Jahrgaenge/J2011.vue";
import Jahr2010 from "./Jahrgaenge/J2010.vue";
import Jahr2009 from "./Jahrgaenge/J2009.vue";
import Jahr2008 from "./Jahrgaenge/J2008.vue";
import Jahr2007 from "./Jahrgaenge/J2007.vue";
import Jahr2006 from "./Jahrgaenge/J2006.vue";
import Jahr2005 from "./Jahrgaenge/J2005.vue";
import Jahr2004 from "./Jahrgaenge/J2004.vue";
import Jahr2003 from "./Jahrgaenge/J2003.vue";
import Jahr2002 from "./Jahrgaenge/J2002.vue";
import Jahr2001 from "./Jahrgaenge/J2001.vue";
import Jahr2000 from "./Jahrgaenge/J2000.vue";

import { openModal } from "jenesius-vue-modal";
import modalBestellung from "../components/ModalBestellung.vue";

export default {
  name: "Mitteilungsblatt",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    Jahr2025,
    Jahr2024,
    Jahr2023,
    Jahr2022,
    Jahr2021,
    Jahr2020,
    Jahr2019,
    Jahr2018,
    Jahr2017,
    Jahr2016,
    Jahr2015,
    Jahr2014,
    Jahr2013,
    Jahr2012,
    Jahr2011,
    Jahr2010,
    Jahr2009,
    Jahr2008,
    Jahr2007,
    Jahr2006,
    Jahr2005,
    Jahr2004,
    Jahr2003,
    Jahr2002,
    Jahr2001,
    Jahr2000,
  },
  methods: {
    openBestellung() {
      openModal(modalBestellung);
    },
  },
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
