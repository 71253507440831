<template>
  <Layout>
    <template #default>
      <div class="container-fluid p-0 m-0 background1">
        <div class="row bg-Hellgrau pt-3 pb-3 justifyCenter" id="t0">
          <div class="col-md-4 t-center spacebetween">
            <div class="div borderRund rundKopf">
              <a href="#t0">
                <h5 v-if="sprachID === 0">Jahreshauptversammlung</h5>
                <h5 v-if="sprachID === 1">annual general meeting</h5>
              </a>
            </div>
            <div class="div borderRund rundKopf">
              <a href="#t1">
                <h5 v-if="sprachID === 0">Herbsttagung</h5>
                <h5 v-if="sprachID === 1">autumn conference</h5>
              </a>
            </div>
          </div>
        </div>
        <div class="row bg-Hellgrau pt-3 pb-3 justifyCenter">
          <div class="col-md-8 t-center text-Weiss" v-if="sprachID === 0">
            Jährlich finden zwei Tagungen statt, im Frühjahr und im Herbst.
            <br />
            Die Frühjahrstagung ist gleichzeitig auch Jahreshauptversammlung der
            Mitglieder des AfM.
          </div>
          <div class="col-md-8 t-center text-Weiss" v-if="sprachID === 1">
            Two conferences are held each year, in spring and autumn. <br />
            The spring conference is also the annual general meeting of the Members of the
            AfM.
          </div>
        </div>

        <div class="row justifyCenter bg-WeissTransparent" id="t0">
          <div class="col-md-10 t-center aussendiv text-Rot-Schatten">
            <div class="pt-5 pb-5">
              <span class="text-Rot-Schatten" v-if="sprachID === 0"
                >Jahreshauptversammlung</span
              >
              <span class="text-Rot-Schatten" v-if="sprachID === 1"
                >annual general meeting</span
              >
            </div>
          </div>
        </div>

        <div class="row justifyCenter pb-5 bg-WeissTransparent">
          <div class="col-md-6 text-center">
            Die JHV 2025 findet am 02.-04.05.2025 wieder in Nümbrecht/Bierenbachtal statt.
            <br />
            Nähere Einzelheiten voraussichtlich in Mtbl. 4/2024
          </div>
        </div>

        <div class="row justifyCenter pb-5 bg-WeissTransparent">
          <div class="col-3">
            <ul class="noDeko p-0">
              <li class="borderRund" @click="openPDF('PDF/Tagesordnung48.pdf')">
                <span v-if="sprachID === 0" class="bold">Tagesordnung</span>
                <span v-if="sprachID === 1" class="bold">agenda </span>
              </li>
            </ul>
          </div>
          <div class="col-3">
            <ul class="noDeko p-0">
              <li class="borderRund" @click="openPDF('PDF/Ablauf48.pdf')">
                <span v-if="sprachID === 0" class="bold">Ablauf</span>
                <span v-if="sprachID === 1" class="bold">scheduling</span>
              </li>
            </ul>
          </div>
        </div>

        <div class="row justifyCenter bg-WeissTransparent pt-5" id="t1">
          <div class="col-md-10 t-center aussendiv text-Rot-Schatten pb-5">
            <h1 v-if="sprachID === 0">Herbsttagung</h1>
            <h1 v-if="sprachID === 1">autumn conference</h1>
          </div>
        </div>
        <div class="row justifyCenter pb-5 bg-WeissTransparent">
          <div class="col-md-6 text-center">
            Die Herbsttagung findet vom 03. bis 05.10.2025 statt.

            
            <br/><br/>

            Best Western Windorf Hotel<br/>
            
            Ernst-Meier-Str. 1, 04249 Leipzig<br/>
            
            Tel.: 0341-42770<br/>
            
            info@windorf.bestwestern.de<br/>

            <br />
            Nähere Einzelheiten folgen rechtzeitig.
          </div>
        </div>
        <div class="row justifyCenter pb-5 bg-WeissTransparent">
          <div class="col-md-6 text-center" v-if="sprachID === 0">
            Informationen werden rechtzeitig im Mitteilungsblatt veröffentlicht.
          </div>
          <div class="col-md-6 text-center" v-if="sprachID === 1">
            Information will be published in the newsletter in due time.
          </div>
        </div>
        <!-- <div class="row justifyCenter pb-5 bg-WeissTransparent">
          <div class="col-6">
            <ul class="noDeko p-0">
              <li
                class="borderRund"
               @click="openPDF('PDF/Herbsttagung_AƒM_2024.pdf')"
              >
                <span v-if="sprachID === 0" class="bold">Einladungsschreiben</span>
                <span v-if="sprachID === 1" class="bold">Invitation letter</span>
              </li>
              
            </ul>
          </div>
        </div> -->
        <!-- <div class="row justifyCenter pb-5 bg-WeissTransparent">
          
          <div class="col-6 px-2 text-center"> 
            <img
            src="../assets/Herbsttagung_AƒM_2024-2.png"
            class=" px-2"
            style="height: 200px;"
            alt="..."
          /> 
            <img
            src="../assets/Herbsttagung_AƒM_2024-1.png"
            class=" px-2"
            style="height: 200px;"
            alt="..."
          /></div>
        </div> -->
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
import { openModal } from "jenesius-vue-modal";
import modalPDF from "../components/ModalPDF.vue";

export default {
  name: "Praxis",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    Layout,
  },
  methods: {
    openPDF(pdf) {
      const props = { pdf: pdf };
      openModal(modalPDF, props);
    },
  },
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
